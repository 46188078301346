<template>
  <div class="plan-banner">
    <h3>数智决策·云图 i-DATA</h3>
    <p>运用阿里云强大的数据整合工具，帮助企业把不同来源、格式、特点性质的数据源<br/>在逻辑上或物理上进行集中，从而为企业提供更为全面的数据共享。</p>
    <a :href="onLine" target="_blank">立即咨询</a>
  </div>
  <Situation/>
  <DataValue/>
  <SSComponent/>
  <SSRoute/>
</template>
<script>
import { onLine } from "@/data.ts";
import Situation from '@/components/planning/Situation.vue';
import DataValue from '@/components/planning/DataValue.vue';
import SSComponent from '@/components/planning/Component.vue';
import SSRoute from '@/components/planning/Route.vue';
export default {
  components:{
    Situation,
    DataValue,
    SSComponent,
    SSRoute
  },
  data() {
    return { onLine }
  }
}
</script>
<style lang="less" scoped>
.plan-banner{
  padding: 65px 25px;
  background: url(/static/img/plan-banner.jpg) no-repeat center;
  background-size: cover;
  color: #fff;
  >h3{
    font-size: 20px;
    font-weight: bold;
  }
  >p{
    font-size: 15px;
    font-weight: 300;
    line-height: 2;
    margin-top: 10px;
  }
  >a{
    line-height: 40px;
    text-align: center;
    display: inline-block;
    height: 40px;
    background: #F23D49;
    border-radius: 3px;
    font-size: 16px;
    font-weight: bold;
    color: #FFFFFF;
    margin-top: 30px;
    border: 0 none;
    width: 115px;
  }
}
</style>